import * as React from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const About = () => {
  const location = useLocation();

  return (
    <Container>
      <small>{location.pathname}</small>
      <h1>ABOUT</h1>
    </Container>
  );
};
