export class CountService {
  value = 0;

  set(val: number): Promise<number> {
    this.value = val;
    return Promise.resolve(this.value);
  }

  add(d: number): Promise<number> {
    this.value += d;
    return Promise.resolve(this.value);
  }
}
