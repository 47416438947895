import * as React from "react";
import { Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { inject, observer } from "mobx-react";

interface IProps {
  appName: string;
}

export const Home = inject("appName")(
  observer((props: IProps) => {
    const history = useHistory();

    return (
      <Container>
        <h1>{props.appName} HOME</h1>
        <Button onClick={() => history.push("/about")}>ABOUT</Button>
      </Container>
    );
  })
);
