import * as React from "react";

export const Footer = () => {
  return (
    <div className={"pt-3 mt-4"}>
      <small className={"text-muted text-center"}>
        Copyright 2020. 곰튀김. all rights reserved.
      </small>
    </div>
  );
};
