import * as React from "react";
import { render } from "react-dom";
import { ServiceContainer } from "../context/RootContainer";
import { Router } from "../router/Router";
import { Provider } from "mobx-react";

const App = () => (
  <Provider {...ServiceContainer}>
    <Router />
  </Provider>
);

render(<App />, document.getElementById("root"));
