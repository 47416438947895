import * as React from "react";
import { CountViewModel } from "./CountViewModel";
import { inject, observer } from "mobx-react";
import { RouteComponentProps } from "react-router-dom";
import { CountService } from "../../../../domain/services/CountService";
import { Button, Container, Spinner } from "react-bootstrap";

interface IProps extends RouteComponentProps {
  CountService: CountService;
}

@inject("CountService")
@observer
export class Counter extends React.Component<IProps> {
  viewModel: CountViewModel | null = null;

  componentWillMount() {
    this.viewModel = new CountViewModel(this.props.CountService);
  }

  render() {
    if (!this.viewModel) return <Spinner animation="grow" variant="light" />;
    const viewModel = this.viewModel!;

    return (
      <Container>
        <h2>{viewModel.model.value}</h2>
        <Button onClick={() => viewModel.minus()}>-1</Button>
        <Button onClick={() => viewModel.plus()}>+1</Button>
      </Container>
    );
  }
}
