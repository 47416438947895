import { action, observable } from "mobx";
import { CountService } from "../../../../domain/services/CountService";

export class CountViewModel {
  private service: CountService;

  constructor(service: CountService) {
    this.service = service;
  }

  @observable model = {
    value: 0,
  };

  @action plus() {
    this.service.add(1).then((val) => (this.model.value = val));
  }

  @action minus() {
    this.service.add(-1).then((val) => (this.model.value = val));
  }
}
