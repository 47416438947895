import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Header } from "../ui/views/common/Header";
import { Footer } from "../ui/views/common/Footer";
import { Home } from "../ui/views/Home";
import { About } from "../ui/views/About";
import { Counter } from "../ui/views/counter/Counter";
import { Container } from "react-bootstrap";

export const Router = () => {
  return (
    <BrowserRouter>
      <Container>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/counter" component={Counter} />
        </Switch>
        <Footer />
      </Container>
    </BrowserRouter>
  );
};
