import * as React from "react";
import { Nav, Navbar } from "react-bootstrap";
// @ts-ignore
import logo from "../../../../resources/images/logo.png";
import { useHistory } from "react-router-dom";

export const Header = () => {
  const history = useHistory();

  return (
    <Navbar className={"pb-3 mb-4"} expand="lg">
      <Navbar.Brand href="/">
        <img src={logo} height={32} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={() => history.push("/")}>Home</Nav.Link>
          <Nav.Link onClick={() => history.push("/counter")}>Counter</Nav.Link>
          <Nav.Link onClick={() => history.push("/about")}>About</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
